import React from 'react'
import Founders from '../components/Founders'
import { Box, Container, Typography } from '@mui/material'
import heroImage from "../assets/img/heavy-machinery-used-construction-industry-engineering.jpg"
import HeaderText from '../components/HeaderText'

const About = () => {
  return (
    <main>
      <section id='hero' 
        style={{
          paddingTop: "0", 
                margin: 0,
                minHeight: "100vh",
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
        }}
      >
        <div 
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width:"100%",
            height:"100%",
            backgroundImage: `url(${heroImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            zIndex:-1
          }}
        >
        </div>
        <div
                style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.7)", 
                zIndex: -1,
                }}
            ></div>

          <Container>
            <Box 
              sx={{
                position: "relative", 
                zIndex: 2, 
                color: "#1d1716",
              }}
            >
              <Typography 
                  variant="h1"
                  textAlign={"left"}
                  fontSize={{ xs: "2.5rem", sm: "3rem", md: "5rem" }}
                  fontWeight="bold"
                  lineHeight={1.1}
                  gutterBottom
                  sx={{
                      letterSpacing: "-0.5px",
                      display:'flex',
                      alignItems:'center',
                      padding: {xs:"4rem 0", md:"4rem 0"},
                      color:'#ffffff',
                      margin:"auto"
                  }}
              >
                A multidisciplinary development and construction company
              </Typography>
              <Typography 
              fontSize={{xs:"1.2rem", md:"1.5rem"}}
                sx={{
                  letterSpacing: "-0.5px",
                  display:'flex',
                  alignItems:'center',
                  color:'#dc2f2f',
                  fontWeight: "bold"
                }}
                >
                  About Us
              </Typography>
            </Box>
          </Container>
      </section>
      
      <section style={{ padding: "7rem 0", backgroundColor: "#f9f9f9", color:"#25161b" }}>
      <Container>
        <Box>
          
            <Typography textAlign="left" fontSize={{xs:"1rem", md:"1.2rem"}} style={{ paddingBottom:"2rem"}}>
              Founded on September 26, 2012, Folkland Property Development Company Limited (FOLKLAND PDC) is a leading name in construction and engineering, driven by a vision of excellence and innovation. With our extensive experience, we have become a reliable partner in the industry with over 150 employees and 200 subcontractors across the country. We specialize in a wide range of services, including road grading, excavation, building construction, furnishing, and project management.
            </Typography>
            <Typography textAlign="left" fontSize={{xs:"1rem", md:"1.2rem"}} style={{ paddingBottom:"2rem"}}>
              Our corporate office is strategically located at 5 A&B, Ikota Villa Estate, beside Mega Chicken, Lekki-Epe Expressway, Ikota, Lagos. With a team of seasoned professionals spanning town planners, civil engineers, architects, quantity surveyors, and land surveyors, we deliver projects with precision, efficiency, and a focus on client satisfaction.",
            </Typography>
            <Typography textAlign="left" fontSize={{xs:"1rem", md:"1.2rem"}} style={{ paddingBottom:"2rem"}}>
              As the construction arm of the renowned PWAN Group, the world’s leading network marketing real estate firm, FOLKLAND PDC plays a pivotal role in shaping urban landscapes. Our connection to the PWAN Group provides access to a robust network of affiliate companies and a clientele base of over 50,000, positioning us as a major player in Nigeria’s real estate industry, particularly within the Lagos Islands, Lekki Axis, and beyond.
            </Typography>
            <Typography textAlign="left" fontSize={{xs:"1rem", md:"1.2rem"}} style={{ paddingBottom:{xs:0, md:"2rem"}}}>
              At FOLKLAND PDC, we are committed to creating sustainable, high-quality solutions that redefine construction and engineering standards in Nigeria.
            </Typography>
        </Box>
      </Container>
      </section>

      <section id='about' style={{padding:"7rem 0", backgroundColor:"#ffffff", color:"#25161b"}}>
            <Container>
                    <Box sx={{textAlign:"left"}}>
                        <HeaderText text={"Who We Are?"} color={"#25161b"}/> 
                    </Box>

                    <Box>
                        <Typography fontSize={{xs:"1rem", md:"1.2rem"}} style={{ paddingBottom:"2rem", textAlign:'left'}}>
                            At FOLKLAND PDC, we are a multidisciplinary development and construction company dedicated to crafting innovative and sustainable solutions for the built environment. As the development and construction arm of the PWAN Group, we bring together expertise in architectural design, structural engineering, surveying, urban planning, civil works, building construction, interior design, and landscaping to deliver exceptional projects that stand the test of time.
                        </Typography>
                        <Typography fontSize={{xs:"1rem", md:"1.2rem"}} style={{ paddingBottom:'2rem', textAlign:'left'}}>
                            Our approach blends technical precision with creative innovation, ensuring that every project reflects our commitment to quality, sustainability, and client satisfaction. From initial concept to final completion, we transform visions into reality, creating spaces that inspire, enrich lives, and shape the future.
                        </Typography>
                        <Typography fontSize={{xs:"1rem", md:"1.2rem"}} style={{ paddingBottom:{xs:0,md:'2rem'}, textAlign:'left'}}>
                            Let FOLKLAND PDC build your future, one remarkable project at a time.
                        </Typography>
                    </Box>
                </Container>
      </section>

      <section style={{backgroundColor:"#000000", position:'relative', overflow:'hidden'}}> 
                    <Box
                      sx={{
                      position: "absolute",
                      top: {xs:"-100px", md:"-50px"},
                      right: {xs:"-200px", md:"-50px"},
                      width: "300px",
                      height: "300px",
                      background: "radial-gradient(circle, #ffffff30, #00000000)",
                      borderRadius: "50%",
                      zIndex: 0,
                      }}
                  />

                  <Box
                      sx={{
                      position: "absolute",
                      bottom:{xs: 0, md: "-120px"},
                      left: {xs: "-100px", md: "-100px"},
                      width: "40px",
                      height: "40px",
                      background: "linear-gradient(135deg, #ffffff20, #00000000)",
                      borderRadius: "50%",
                      zIndex: 0,
                      }}
                  />
                  <Box
                      sx={{
                      position: "absolute",
                      top: {xs:"-50px", md:"10px"},
                      left: {xs:"-100px", md:"-100px"},
                      width: "200px",
                      height: "200px",
                      backgroundColor: "#ffffff10",
                      transform: "rotate(45deg)",
                      zIndex: 0,
                      }}
                  />
                  <Box
                      sx={{
                      position: "absolute",
                      bottom: {xs:"50px", md:"70px"},
                      right: {xs:"-10px", md: "300px"},
                      width: "120px",
                      height: "120px",
                      backgroundColor: "#ffffff15",
                      borderRadius: "50%",
                      zIndex: 0,
                      }}
                  />
        <Container>
          <Founders color={"#ffffff"}/>
        </Container>
      </section>
    </main>
  )
}


export default About