import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Grid, Grid2, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import heroImage from '../assets/img/Hero.jpg'
import HeaderText from '../components/HeaderText'
import { FAQS } from '../data/faqs'
import Founders from '../components/Founders'
import { ExpandMore, ArrowForward} from '@mui/icons-material'
import TestimonialCarousel from '../components/Carousel'
import { BranchCards } from '../components/BranchCards'
import { values } from '../data/values'
import { services } from '../data/services'
import useScrollTriggeredCountUp from '../components/CountUp'
import { affiliates } from '../data/affiliates'
import Marquee from "react-fast-marquee"




const Home = () => {
    const [isVisible, setIsVisible] = useState(false); 
    const sectionRef = useRef(null); 

    const staffRef = useRef(null);
    const contractorRef = useRef(null);
    const projectRef = useRef(null);
    const clientRef = useRef(null);

    const staffCount = useScrollTriggeredCountUp(staffRef, 150, 2000);
    const contractorCount = useScrollTriggeredCountUp(contractorRef, 200, 2000);
    const projectCount = useScrollTriggeredCountUp(projectRef, 1000, 2000);
    const clientCount = useScrollTriggeredCountUp(clientRef, 50000, 2000);



    useEffect(() => {
        const observer = new IntersectionObserver(
          (entries) => {
            const entry = entries[0];
            if (entry.isIntersecting) {
              setIsVisible(true);
            } else {
              setIsVisible(false);
            }
          },
          { threshold: 0.3 }
        );
      
        const currentSection = sectionRef.current; 
      
        if (currentSection) {
          observer.observe(currentSection);
        }
      
        return () => {
          if (currentSection) {
            observer.unobserve(currentSection);
          }
        };
      }, []); 


  return (
    <main>
        <section id="hero"
            style={{
                paddingTop: "0", 
                margin: 0,
                minHeight: "100vh",
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
            >
            <div
                style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundImage: `url(${heroImage})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                zIndex: -2,
                }}
            ></div>

            <div
                style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.7)",
                zIndex: -1,
                }}
            ></div>

            <Container>
                <Box textAlign={{xs:"left", md:"center"}} style={{  color: "#fff" }}>
                <Typography
                    variant="h1"
                    fontSize={{ xs: "2.5rem", sm: "3rem", md: "5rem" }}
                    fontWeight="bold"
                    lineHeight={1.1}
                    sx={{
                    letterSpacing: "-0.5px",
                    }}
                >
                    A property development company that keeps its promise on&nbsp;
                    <span 
                        style={{
                            backgroundImage: "linear-gradient(135deg, rgba(220, 47, 47, 0.8), rgba(0, 0, 0, 0.9))",
                            display:"inline-block",
                        }}
                        >
                            &nbsp;building reality.&nbsp;
                    </span>
                </Typography>
                </Box>
            </Container>
        </section>

        <section id='about' style={{padding:"7rem 0", color:"#25161b"}}>
            <Container>
                    <Box sx={{textAlign:"left"}}>
                        <HeaderText text={"Who We Are?"} /> 
                    </Box>

                    <Box>
                        <Typography fontSize={{xs:"1rem", md:"1.2rem"}} style={{padding:"1rem 0", textAlign:'left'}}>
                            At FOLKLAND PDC, we are a multidisciplinary development and construction company dedicated to crafting innovative and sustainable solutions for the built environment. As the development and construction arm of the PWAN Group, we bring together expertise in architectural design, structural engineering, surveying, urban planning, civil works, building construction, interior design, and landscaping to deliver exceptional projects that stand the test of time.
                        </Typography>
                        <Typography fontSize={{xs:"1rem", md:"1.2rem"}} style={{ padding:'1rem 0', textAlign:'left'}}>
                            Our approach blends technical precision with creative innovation, ensuring that every project reflects our commitment to quality, sustainability, and client satisfaction. From initial concept to final completion, we transform visions into reality, creating spaces that inspire, enrich lives, and shape the future.
                        </Typography>
                        <Typography fontSize={{xs:"1rem", md:"1.2rem"}} style={{ padding:'1rem 0', textAlign:'left'}}>
                            Let FOLKLAND PDC build your future, one remarkable project at a time.
                        </Typography>
                    </Box>
                    <Typography fontSize={{xs:"1rem", md:"1.2rem"}} display={"flex"} alignItems={"center"} sx={{textAlign:"right"}}>
                        <a href={"/about"} style={{textDecoration:"none", padding:"1rem 0", color:"#25161b", display:'flex', alignItems:"center"}}>Learn more <ArrowForward fontSize='2.5rem' sx={{marginLeft:1}}/></a>
                        
                    </Typography>
                </Container>
        </section>

        <section id='vision' style={{paddingBottom:"7rem"}}>
        <Container>
            <Box >
            <Grid2
                container
                spacing={3}
                sx={{
                flexDirection: { xs: "column", md:"row", lg: "row" }, 
                }}
            >

                <Grid2
                xs={12}
                md={6}
                lg={6}
                size={{xs:12, md:6}}
                sx={{
                    backgroundColor: "#1d1716",
                    borderRadius: "10px",
                    padding: { xs: "1rem", md: "2rem" },
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                    position: "relative", 
                    overflow: "hidden", 
                }}
                >
                    <Box
                        sx={{
                        position: "absolute",
                        top: "-50px",
                        right: "-50px",
                        width: "150px",
                        height: "150px",
                        background: "radial-gradient(circle, #ffffff30, #00000000)",
                        borderRadius: "50%",
                        zIndex: 0,
                        }}
                    />
                    <Box
                        sx={{
                        position: "absolute",
                        bottom: "-70px",
                        left: "-70px",
                        width: "200px",
                        height: "200px",
                        background: "linear-gradient(135deg, #ffffff20, #00000000)",
                        borderRadius: "50%",
                        zIndex: 0,
                        }}
                    />
                    <Box
                        sx={{
                        position: "absolute",
                        top: "40px",
                        left: "-30px",
                        width: "60px",
                        height: "60px",
                        backgroundColor: "#ffffff10",
                        transform: "rotate(45deg)",
                        zIndex: 0,
                        }}
                    />
                    <Box
                        sx={{
                        position: "absolute",
                        bottom: "20px",
                        right: "30px",
                        width: "40px",
                        height: "40px",
                        backgroundColor: "#ffffff15",
                        borderRadius: "50%",
                        zIndex: 0,
                        }}
                    />
                    <Box height={165} sx={{ position: "relative", zIndex: 1 }}>
                        <Typography
                            fontSize= {{ xs: "1.5rem", lg: "2rem" }}
                            variant="h4"
                            sx={{
                            color: "#ffffff",
                            marginBottom: "1.2rem",
                            fontWeight: 700,
                            }}
                        >
                            Vision
                        </Typography>
                        <Typography
                            fontSize= {{ xs: "1rem", lg: "1.2rem" }}
                            sx={{
                            color: "#ffffff",
                            fontWeight: 300,
                            }}
                        >
                            To be a leading construction and property development company with
                            the key objective of making the homeownership dream a reality for
                            all.
                        </Typography>
                    </Box>
                
                </Grid2>

                <Grid2
                xs={12}
                lg={6}
                size={{xs:12, md:6}}
                sx={{
                    backgroundColor: "#dc2f2f",
                    borderRadius: "10px",
                    padding: { xs: "1rem", md: "2rem" },
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                    position: "relative", 
                    overflow: "hidden", 
                }}
                >
                    <Box
            sx={{
              position: "absolute",
              top: "-50px",
              right: "-50px",
              width: "150px",
              height: "150px",
              background: "radial-gradient(circle, #ffffff40, #00000000)",
              borderRadius: "50%",
              zIndex: 0,
            }}
          />
          <Box
            sx={{
              position: "absolute",
              bottom: "-70px",
              left: "-70px",
              width: "200px",
              height: "200px",
              background: "linear-gradient(135deg, #ffffff30, #00000000)",
              borderRadius: "50%",
              zIndex: 0,
            }}
          />
          <Box
            sx={{
              position: "absolute",
              top: "30px",
              left: "30px",
              width: "70px",
              height: "70px",
              backgroundColor: "#ffffff15",
              transform: "rotate(45deg)",
              zIndex: 0,
            }}
          />
          <Box
            sx={{
              position: "absolute",
              bottom: "20px",
              right: "40px",
              width: "50px",
              height: "50px",
              backgroundColor: "#ffffff10",
              borderRadius: "50%",
              zIndex: 0,
            }}
          />
                <Box height={165} sx={{ position: "relative", zIndex: 1 }}>
                    <Typography
                    fontSize={{ xs: "1.5rem", lg: "2rem" }}
                    variant="h4"
                    sx={{
                    color: "#ffffff",
                    marginBottom: "1.2rem",
                    fontWeight: 700,
                    }}
                >
                    Mission
                </Typography>
                <Typography
                    fontSize= {{xs: "1rem", lg: "1.2rem"}}
                    sx={{
                    color: "#ffffff",
                    fontWeight: 300,
                    }}
                >
                    To research and innovate around designs, construction techniques,
                    building materials, and housing solutions that offer the most
                    affordable prices and deploy the same towards achieving our
                    objective.
                </Typography>
                    </Box>
                
                </Grid2>
            </Grid2>
            </Box>
        </Container>
        </section>

        <section id='statistics'
            style={{
                backgroundColor: "#ffffff", 
                padding: "0 0 7rem 0",
            }}
        >
        <Container>
            <Grid container spacing={3}>
                <Grid item xs={6} sm={6} md={3}  ref={staffRef}>
                    <Box
                        sx={{
                        textAlign: "center",
                        borderRight: {xs: "1px solid #ddd", sm: "1px solid #ddd", md: "1px solid #ddd"},
                        borderBottom: {md: "none", sm: "1px solid #ddd", xs: "1px solid #ddd"},
                        padding: {xs:"1rem 0", sm:"0 1rem", md:"0 1rem"},
                        }}
                    >
                        <Typography
                        variant="h3"
                        fontSize={{xs:"2.5rem", sm:"3.5rem", md:"3.5rem"}}
                        sx={{
                            fontWeight: "bold",
                            color: "#d32f2f", 
                            marginBottom: "0.5rem",
                        }}
                        >
                        {staffCount}+
                        </Typography>
                        <Typography
                        variant="body1"
                        fontSize={{xs:"1rem", md:"1.5rem"}}
                        sx={{
                            fontWeight: "bold",
                            color: "#555", 
                        }}
                        >
                        Staffs
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={6} sm={6} md={3}  ref={contractorRef}>
                    <Box
                        sx={{
                        textAlign: "center",
                        borderRight: {xs: "1px solid #ddd", sm: "1px solid #ddd", md:"1px solid #ddd"} ,
                        borderBottom: {md: "none", sm: "1px solid #ddd", xs: "1px solid #ddd"},
                        padding: {xs:"1rem 0", sm:"0 1rem", md:"0 1rem"},
                        }}
                    >
                        <Typography
                        variant="h3"
                        fontSize={{xs:"2.5rem", sm:"3.5rem", md:"3.5rem"}}
                        sx={{
                            fontWeight: "bold",
                            color: "#d32f2f", 
                            marginBottom: "0.5rem",
                        }}
                        >
                        {contractorCount}+
                        </Typography>
                        <Typography
                        variant="body1"
                        fontSize={{xs:"1rem", md:"1.5rem"}}
                        sx={{
                            fontWeight: "bold",
                            color: "#555", 
                        }}
                        >
                        Subcontractors
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={6} sm={6} md={3}  ref={projectRef}>
                    <Box
                        sx={{
                        textAlign: "center",
                        borderRight: {xs: "1px solid #ddd", sm: "1px solid #ddd", md: "1px solid #ddd"},
                        borderBottom: {md: "none", sm: "1px solid #ddd", xs: "1px solid #ddd"},
                        padding: {xs:"1rem 0", sm:"0 1rem", md:"0 1rem"},
                        }}
                    >
                        <Typography
                        variant="h3"
                        fontSize={{xs:"2.5rem", sm:"3.5rem", md:"3.5rem"}}
                        sx={{
                            fontWeight: "bold",
                            color: "#d32f2f", 
                            marginBottom: "0.5rem",
                        }}
                        >
                        {new Intl.NumberFormat('en-US').format(projectCount)}+

                        </Typography>
                        <Typography
                        variant="body1"
                        fontSize={{xs:"1rem", md:"1.5rem"}}
                        sx={{
                            fontWeight: "bold",
                            color: "#555", 
                        }}
                        >
                        Projects
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={6} sm={6} md={3}  ref={clientRef}>
                    <Box
                        sx={{
                        textAlign: "center",
                        borderRight: {xs: "1px solid #ddd", sm: "1px solid #ddd", md:"none"} ,
                        borderBottom: {md: "none", sm: "1px solid #ddd", xs: "1px solid #ddd"},
                        padding: {xs:"1rem 0", sm:"0 1rem", md:"0 1rem"},
                        }}
                    >
                        <Typography
                        variant="h3"
                        fontSize={{xs:"2.5rem", sm:"3.5rem", md:"3.5rem"}}
                        sx={{
                            fontWeight: "bold",
                            color: "#d32f2f", 
                            marginBottom: "0.5rem",
                        }}
                        >
                            {new Intl.NumberFormat('en-US').format(clientCount)}+

                        </Typography>
                        <Typography
                        variant="body1"
                        fontSize={{xs:"1rem", md:"1.5rem"}}
                        sx={{
                            fontWeight: "bold",
                            color: "#555", 
                        }}
                        >
                        Clients
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Container>
        </section>

        <section id="core-value" ref={sectionRef} style={{ padding: "7rem 0", position: "relative", overflow: "hidden", backgroundColor: "#f9f9f9" }}>
            <Container>
                <Box textAlign="center">
                <HeaderText text={"Our Core Values"} color={"#25161b"} />
                </Box>

                <Grid container spacing={2} sx={{ mt: 4, justifyContent: "center" }}>
                {values.slice(0, 3).map((value, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                    <Box
                        sx={{
                        display: "flex",
                        alignItems: "center",
                        borderRadius: 1,
                        border: "1px solid #ccc",
                        padding: "1.5rem",
                        minHeight: "120px",
                        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                        opacity: isVisible ? 1 : 0,
                        transform: isVisible ? "translateY(0)" : "translateY(20px)",
                        transition: "opacity 0.5s ease, transform 0.5s ease",
                        }}
                    >
                        <Box
                        sx={{
                            backgroundColor: value.color,
                            color: "#fff",
                            width: 50,
                            height: 50,
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginRight: 2,
                        }}
                        >
                        <Typography variant="h5">{value.letter}</Typography>
                        </Box>
                        <Typography variant="body1" fontSize={{ xs: "1rem", sm: "1.2rem", md: "1.2rem" }}>
                        {value.description}
                        </Typography>
                    </Box>
                    </Grid>
                ))}

                <Grid container item xs={12} sx={{ justifyContent: "center", mt: 2, gap: 2 }}>
                    {values.slice(3).map((value, index) => (
                    <Grid item xs={12} sm={5} md={4} key={index}>
                        <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            borderRadius: 1,
                            border: "1px solid #ccc",
                            padding: "1.5rem",
                            minHeight: "120px",
                            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                            opacity: isVisible ? 1 : 0,
                            transform: isVisible ? "translateY(0)" : "translateY(20px)",
                            transition: "opacity 0.5s ease, transform 0.5s ease",
                        }}
                        >
                        <Box
                            sx={{
                            backgroundColor: value.color,
                            color: "#fff",
                            width: 50,
                            height: 50,
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginRight: 2,
                            }}
                        >
                            <Typography variant="h5">{value.letter}</Typography>
                        </Box>
                        <Typography variant="body1" fontSize={{ xs: "1rem", sm: "1.2rem", md: "1.2rem" }}>
                            {value.description}
                        </Typography>
                        </Box>
                    </Grid>
                    ))}
                </Grid>
                </Grid>
            </Container>
        </section>

        <section id="services" style={{ padding: "7rem 0", backgroundColor: "#ffffff" }}>
            <Container maxWidth={"lg"}>
            <Box>
                <HeaderText text={"Our Services"} color={"#25161b"}/>
            </Box>

            <Grid container spacing={{xs:2, md:5}} padding={"2rem 0"} display={"flex"} justifyContent={{ xs: "center", sm: "space-around", md: "space-between" }}>
                {services.map((service, index) => (
                <Grid item xs={6} sm={6} md={2.4} key={index}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            height:{xs:"150px", md:"200px"},
                            width: {xs:"100%", md:"215px"},
                            border: "1px solid #eee",
                            borderRadius: "8px",
                            padding: {xs:".5rem", md:"1rem"},
                            textAlign: "center",
                        }}
                    >
                    <Box
                        sx={{
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: 2,
                        color: "#dc2f2f",
                        }}
                    >
                        {service.icon}
                    </Box>

                    <Typography fontSize= {{xs:"1rem", md:"1.1rem" }} sx={{ color: "#25161b", fontWeight: 600}}>
                        {service.title}
                    </Typography>
                    </Box>
                </Grid>
                ))}
            </Grid>
            </Container>
        </section>

        <section id='founders' 
            style={{
                backgroundColor: "#000000", 
                position: "relative", 
                overflow: "hidden" 
            }}
        >
                    <Box
                        sx={{
                        position: "absolute",
                        top: {xs:"-300px", md:"-140px"},
                        right: {xs:"-300px", md:"-140px"},
                        width: "500px",
                        height: "500px",
                        background: "radial-gradient(circle, #ffffff30, #00000000)",
                        borderRadius: "50%",
                        zIndex: 0,
                        }}
                    />

                    <Box
                        sx={{
                        position: "absolute",
                        bottom: {xs:"-200px", md:"-200px"},
                        left: {xs:"-200px", md:"-200px"},
                        height: "400px",
                        width: "400px",
                        background: "linear-gradient(135deg, #ffffff20, #00000000)",
                        borderRadius: "50%",
                        zIndex: 0,
                        }}
                    />
                    <Box
                        sx={{
                        position: "absolute",
                        top: {xs:"-30px", md:"10px"},
                        left: {xs:"-120px",md:"-100px"},
                        width: "200px",
                        height: "200px",
                        backgroundColor: "#ffffff10",
                        transform: "rotate(45deg)",
                        zIndex: 0,
                        }}
                    />
                    <Box
                        sx={{
                        position: "absolute",
                        bottom: {xs:"40px", md:"70px"},
                        right: {xs:"-20px", md:"300px"},
                        width: {xs:"100px", md:"120px"},
                        height: {xs:"100px", md:"120px"},
                        backgroundColor: "#ffffff15",
                        borderRadius: "50%",
                        zIndex: 0,
                        }}
                    />
                        <Container>
                            <Founders color={"#ffffff"}/>
                        </Container>
        </section>

        <section id="branches" style={{ padding: "7rem 0", backgroundColor: "#ffffff" }}>
            <BranchCards/>
        </section>

        <section id="affiliates" style={{ backgroundColor: "#ffffff", borderTop:"1px solid #f9f9f9" }}>
            <Container maxWidth="lg">
            <Box
                sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                }}
            >
                <Box
                sx={{
                    width: 160,
                }}
                >
                <Typography
                    variant="body1"
                    fontSize="16px"
                    fontWeight={500}
                    color="#000000"
                    gutterBottom
                    sx={{
                    letterSpacing: "-0.5px",
                    }}
                >
                    PWAN AFILLIATES
                </Typography>
                </Box>

                <Marquee>
                    {affiliates.map((aff, index) => {
                        return (
                        <div key={aff.id}>
                            <img
                            src={aff.image}
                            alt="Sponsors' Logo"
                            width={80}
                            height={aff.height}
                            style={{ margin: "0 20px" }}
                            />
                        </div>
                        );
                    })}
                </Marquee>
            </Box>
            </Container>
        </section>

        <section id="faq"
            style={{
                padding: "7rem 0",
                backgroundColor: "#f9f9f9",
            }}
            >
            <Container maxWidth="lg">
                <Box textAlign="center" marginBottom="3rem">
                <HeaderText text={"Frequently Asked Questions (FAQ)"} color={"#25161b"} />
                </Box>
                <Grid container spacing={2}>
                {FAQS.map((faq) => (
                    <Grid
                    item
                    xs={12} 
                    sm={6} 
                    md={6} 
                    key={faq.id}
                    >
                    <Accordion
                        sx={{
                        backgroundColor: "white",
                        borderRadius: "8px",
                        padding: {xs:0, md:"0 1rem"},
                        boxShadow: "none" 
                        }}
                    >
                        <AccordionSummary
                        expandIcon={<ExpandMore sx={{ color: "#25161b", fontSize: "1.8rem" }} />}
                        aria-controls={`panel${faq.id}-content`}
                        id={`panel${faq.id}-header`}
                        >
                        <Typography
                            fontSize={{
                            xs: "1rem",
                            sm: "1rem",
                            md: "1.2rem",
                            }}
                            fontWeight={700}
                            color="#25161b"
                            letterSpacing="-0.5px"
                            sx={{
                            minHeight:{xs:60, sm:100,md:60},
                            display: "flex",
                            alignItems: "center",
                            }}
                        >
                            {faq.question}
                        </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Typography
                            variant="body1"
                            fontSize={{
                            xs: "1rem",
                            sm: "1rem",
                            md: "1.2rem",
                            }}
                            fontWeight={400}
                            color="#000000"
                            textAlign={"left"}
                            letterSpacing="-0.5px"
                            height={{xs:120, sm: 180, md:100}}
                        >
                            {faq.answer}
                        </Typography>
                        </AccordionDetails>
                    </Accordion>
                    </Grid>
                ))}
                </Grid>
            </Container>
        </section>

        <section id='testimonials' style={{
            padding: "7rem 0", 
            backgroundImage: `url(${heroImage})`,  
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            position: 'relative',
        }}
        > 
            
            <Box
                sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',  
                backdropFilter: 'blur(8px)',  
                zIndex: 1,  
                }}
            />
            <Container>
                <Box sx={{ position: 'relative', zIndex: 2 }}>
                <HeaderText text={"Testimonials"} color={"#ffffff"}/>
                <TestimonialCarousel />
                </Box>
            </Container>
        </section>

    </main>
  )
}

export default Home