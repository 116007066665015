import React from 'react';
import { Container, Box, Typography, Grid } from "@mui/material";
import { LocationOnOutlined } from "@mui/icons-material";
import HeaderText from './HeaderText';

export const BranchCards = () => {
  const branches = [
    {
      title: "Head Office",
      address: "5A & 5B Ikota villa estate, Ikota, besides Megachicken, Lekki-Epe expressway Ajah, Lagos",
    },
    {
      title: "Branch Office",
      address: "LINPIS Mall opposite GLOBUS Bank, Okpanam Road, Asaba",
    },
  ];

  return (
    <div>
      <Container>
        <Box>
          <HeaderText text={"Our Branches"} />
        </Box>

        <Grid container spacing={5} style={{ padding: "0"}}>
          {branches.map((branch, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Box
                  height={{xs:140 ,md:150}}
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: 2,
                    padding: { xs: ".8rem", md: "1.5rem" },
                    borderRadius: "8px",
                    border: "1px solid #ccc",
                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                  }}
              >
                <LocationOnOutlined sx={{ fontSize: { xs: "25px", sm: "35px", md: "40px" }, color: "#dc2f2f", marginTop:"2px" }} />
                <Box>
                  <Typography
                    fontSize={{ xs: "1.2rem", sm: "1.4rem", md: "1.5rem" }}
                    sx={{
                      fontWeight: 700,
                      marginBottom: "0.5rem",
                      color: "#25161b",
                      textAlign: "left",
                    }}
                  >
                    {branch.title}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "1rem", sm: "1.2rem", md: "1.2rem" },
                      color: "#333",
                      textAlign: "left",
                    }}
                  >
                    {branch.address}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};
