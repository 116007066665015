import React, { useEffect, useState } from "react";
import {AppBar, Toolbar, IconButton, Drawer, List, ListItem, Button, Box, Container} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../../assets/img/Asset 3.png";

const navLinks = [
  { name: "Home", path: "/" },
  { name: "About", path: "/about" },
  { name: "Projects", path: "/projects" },
  { name: "Organogram", path: "/organogram" },
  { name: "Contact Us", path: "/contact" },
];

const NavBar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false)
  const location = useLocation();
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <AppBar
      position="static"
      color="default"
      elevation={0}
      sx={{
        position: scrolled
        ? "fixed" 
        : "absolute", 
        padding: { xs: scrolled ? ".5rem 0" : "3rem 0", sm: "1rem 0", md: ".8rem 0" },
        zIndex: 3,
        backgroundColor:  scrolled
        ? "rgba(0, 0, 0, 0.8)" 
        : "transparent"
         
      }}
    >
      <Container maxWidth="lg">
        <Toolbar
          disableGutters
          sx={{ display: "flex", justifyContent: "space-between", alignItems:"center" }}
        >
          <Box
            sx={{
              width: "60px" ,
              marginBottom: 0,
            }}
          >
            <a href="/">
            <img
              src={logo}
              alt="Folkland Logo"
              style={{ width: "100%", height: "auto" }}
            />
            </a>
            
          </Box>

          <Box sx={{ display: { xs: "none", lg: "flex" } }}>
            {navLinks.map((link) => (
              <Button
                key={link.name}
                variant="text"
                onClick={() => navigate(link.path)}
                sx={{
                  paddingLeft: 3,
                  paddingRight: 3,
                  fontWeight: "bold",
                  fontSize:"1rem",
                  color: location.pathname === link.path
                      ? "#dc2f2f" 
                      : "#ffffff",
                  textTransform: "none",
                  "&:hover": {
                    background:"none"
                  }
                }}
              >
                {link.name}
              </Button>
            ))}
          </Box>

          <Box sx={{ display: { xs: "block", lg: "none" } }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerToggle}
            >
              <MenuIcon sx={{ fontSize: "2.2rem", color: "#ffffff" }} />
            </IconButton>
          </Box>
        </Toolbar>
      </Container>

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        sx={{
          display: { xs: "block", lg: "none" },
          "& .MuiDrawer-paper": {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            backdropFilter: "blur(6px)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            zIndex:2,
            height:{xs:500, sm: 800}
          },
        }}
      >
        <Box sx={{ width: 380 }}>
          <IconButton
            onClick={handleDrawerToggle}
            sx={{
              padding: 2,
              color: "#25161b",
              marginTop: 3,
              position: "absolute",
              right: 10,
              top: 10,
            }}
          >
            <CloseIcon sx={{ fontSize: "2.2rem", color: "#ffffff" }} />
          </IconButton>

          <List sx={{ position: "relative", top: "35%" }}>
            {navLinks.map((link) => (
              <ListItem key={link.name}>
                <Button
                  variant="text"
                  fullWidth
                  onClick={() => {
                    handleDrawerToggle();
                    navigate(link.path);
                  }}
                  sx={{
                    textTransform: "none",
                    fontWeight: "bold",
                    color: location.pathname === link.path
                    ? "#dc2f2f" 
                    : "#ffffff",
                    fontSize: "1.25rem",
                    textAlign: "left",
                  }}
                >
                  {link.name}
                </Button>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </AppBar>
  );
};

export default NavBar;
