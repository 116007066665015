import { createTheme } from "@mui/material/styles";


const theme = createTheme({
    typography: {
      fontFamily: "Nunito, serif", 
    },
  });

export default theme;
