import React, { useState, useEffect } from "react";
import { Fab } from "@mui/material"; 
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"; 

const BackToTop = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {showButton && (
        <Fab
          onClick={scrollToTop}
          color="primary"
          size="small"
          style={{
            position: "fixed",
            bottom: "2rem",
            right: "2rem",
            zIndex: 1000,
            backgroundImage: "linear-gradient(135deg, rgba(220, 47, 47, 0.8), rgba(0, 0, 0, 0.9))",
            color: "#ffffff50",
            border: "none",
            borderRadius: "50%",
            width: {xs:"12px", md: "50px"},
            height: {xs:"12px", md: "50px"},
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
            "&:hover": {
                backgroundImage: "linear-gradient(135deg, rgba(220, 47, 47, 0.8), rgba(0, 0, 0, 0.9))"
            }
            }}
                    >
          <KeyboardArrowUpIcon />
        </Fab>
      )}
    </>
  );
};

export default BackToTop;
