import { Route, BrowserRouter as Router, Routes } from 'react-router';
import Home from './view/Home';
import NavBar from './components/globalComponents/Nav';
import Footer from './components/globalComponents/Footer';
import About from './view/About';
import Organogram from './view/Organogram';
import Projects from './view/Projects';
import Contact from './view/Contact';
import ScrollToTop from './components/ScrollToTop';
import BackToTop from './components/BackToTop';

function App() {
  return (
    <Router>
      <BackToTop/>
      <ScrollToTop/>
    <NavBar/>
      <div className="App">
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/about' element={<About/>}/>
          <Route path='/projects' element={<Projects/>}/>
          <Route path='/organogram' element={<Organogram/>}/>
          <Route path='/contact' element={<Contact/>}/>
        </Routes>
      </div>
    <Footer/>
    </Router>
    
  );
}

export default App;
