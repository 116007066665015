import { Grid, Typography, CardMedia, Card, CardContent } from '@mui/material'
import React from 'react'
import HeaderText from './HeaderText'
import founder1 from "../assets/img/chairman.png"
import founder2 from "../assets/img/president.jpeg"

const founders = [
  { id: 1, name: 'Dr. Augustine Onwumere', role: 'Co-Founder', image: founder1 },
  { id: 2, name: 'Dr. (Mrs) Jayne Onwumere', role: 'Co-Founder', image: founder2 },
];

const Founders = ({color}) => {
  return (
  <section style={{padding: "7rem 0"}}>
  <HeaderText text={"Meet Our Founders"} color={color} />
  <Grid container spacing={4} justifyContent="center" marginY={2}>
    {founders.map((founder) => (
      <Grid item xs={12} sm={6} md={4} key={founder.id}>
        <Card
          sx={{
            textAlign: 'center',
            borderRadius: '12px',
            boxShadow: 3,
            height: '100%', 
            display: 'flex',
            border: "1px solid #ffffff",
            flexDirection: 'column',
            justifyContent: 'space-between',
            margin:{xs:"0rem 1rem", md:0}

          }}
        >
          <CardMedia
            component="img"
            height= {"350"} 
            image={founder.image}
            alt={founder.name}
            sx={{
              objectFit: 'cover', 
              borderRadius: '12px 12px 0 0',
            }}
          />
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Typography fontSize={{xs:"1rem", sm:"1rem", md:"1.1rem"}} fontWeight={"bold"} gutterBottom>
              {founder.name}
            </Typography>
            <Typography fontSize={".8rem"} color="text.secondary">
              {founder.role}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    ))}
  </Grid>
</section>

   
    
  )
}

export default Founders
