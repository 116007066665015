import React from "react";
import Slider from "react-slick";
import { Typography, Box, Card, CardContent } from "@mui/material";

const useStyles = {
  container: {
    maxWidth: "1000px",
    margin: "0 auto",
    padding: "20px",
    
  },
  card: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px 10px",
    boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
    borderRadius: "10px",
    backgroundColor: "#f9f9f9",
    minHeight: "250px",
    textAlign: "center",
  },
  quote: {
    fontSize: {xs:"1rem", md:"1.2rem"},
    fontStyle: "italic",
    color: "#555",
  },
  author: {
    fontSize: {xs:"1.2rem", sm:"1.4rem", md:"1.5rem"},
    marginTop: "20px",
    fontWeight: "bold",
    color: "#333",
  },
};

const TestimonialCarousel = () => {
  const classes = useStyles;

  const testimonials = [
    {
      quote: "FOLKLAND PDC brought our dream estate to life with exceptional layout designs and flawless execution. We couldn’t be happier with the results!",
      author: "Olusegun A., Lagos",
    },
    {
      quote: "The landscaping done by FOLKLAND PDC transformed our estate into a serene and inviting environment. Their work is truly remarkable!",
      author: "Chijioke N., Enugu",
    },
    {
      quote: "Thanks to FOLKLAND PDC, our property value has appreciated significantly. Their attention to detail in construction and layout design is second to none",
      author: "Ngozi K., Lekki",
    },
    {
      quote: "The structural quality of our newly constructed estate is outstanding. FOLKLAND PDC’s expertise in building construction is evident in every detail.",
      author: "Emeka I., Abuja",
    },
    {
      quote: "Our estate landscaping by FOLKLAND PDC has enhanced both the beauty and the value of our property. We highly recommend their services.",
      author: "Victoria E., Port Harcourt",
    },
    {
      quote: "We’re grateful to FOLKLAND PDC for their meticulous estate design and top-quality construction. The value of our property has doubled in a short time.",
      author: "Ahmed B., Ikeja",
    },
    {
      quote: "The value of our estate has soared thanks to the top-notch construction work by FOLKLAND PDC. They are true professionals.",
      author: "Chinedu N., Asaba",
    },
    {
      quote: "Residents have been raving about the estate’s stunning landscaping and solid construction. FOLKLAND PDC truly delivered!",
      author:"Esther I., Lekki"
    },
    {
      quote: "FOLKLAND PDC’s work on our estate landscaping is nothing short of amazing. They’ve created a beautiful, functional environment",
      author:"Damilare T., Akure"
    },
    {
      quote: "The construction quality provided by FOLKLAND PDC has set a new standard in our area. Our property value has increased significantly.",
      author:"Tunde A., Owerri"
    },
    {
      quote: "Thanks to FOLKLAND PDC, our estate has become a prime location with a well-thought-out layout and impeccable landscaping.",
      author:"Adaora M., Lekki Phase 1"
    }
  ];

  const CustomNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          borderRadius: "50%",
          padding: "10px",
          position: "absolute", 
          right: "-65px", 
          top: "40%", 
          transform: "translateY(-50%)", 
          zIndex: 2, 
        }}
        onClick={onClick}
      />
    );
  };
  
  const CustomPrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          fontSize:"50px",
          display: "block",
          borderRadius: "50%",
          padding: "10px",
          position: "absolute", 
          left: "-100px", 
          top: "40%", 
          transform: "translateY(-50%)", 
          zIndex: 2, 
        }}
        onClick={onClick}
      />
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          arrows: false, 
          dots: true,
        },
        // breakpoint: 768,
        // settings: {
        //   arrows: false, 
        //   dots: true,
        // },

        
      },
    ],
  };

  return (
    <Box sx={classes.container}>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <Card sx={classes.card} key={index}>
            <CardContent>
              <Typography variant="body1" sx={classes.quote}>
                "{testimonial.quote}"
              </Typography>
              <Typography variant="h6" sx={classes.author}>
                - {testimonial.author}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Slider>
    </Box>
  );
};

export default TestimonialCarousel;


