export const FAQS = [
    {
      id: 1,
      question: "Can you handle large-scale estate development projects?",
      answer:
        "Yes, we have extensive experience in designing and constructing large-scale estates, including layout designs, landscaping, and complete building solutions.",
    },
    {
      id: 2,
      question: "Do you provide cost estimates for projects?",
      answer: "Absolutely yes. After understanding your project requirements, we prepare a detailed cost estimate to give you a clear understanding of the expenses involved.",
    },
    {
      id: 3,
      question: "What makes FOLKLAND PDC different from other construction companies?",
      answer: "Our commitment to quality, innovative designs, and sustainable solutions sets us apart. We have a team of highly skilled professionals and a track record of delivering projects on time and to the highest standards.",
    },
    {
      id: 4,
      question: "Can you assist with estate landscaping and beautification?",
      answer: "Yes, we specialize in creating beautiful and functional landscapes for estates, ensuring they are aesthetically pleasing and environmentally sustainable.",
    },
    {
      id: 5,
      question: "How long does it take to complete a project?",
      answer: "Project timelines vary depending on the size and complexity. During our consultation, we’ll provide a realistic timeline tailored to your specific project.",
    },
    {
      id: 6,
      question: "Do you offer structural or architectural design services separately?",
      answer: "Yes, we provide standalone architectural and structural design services. Whether you need a new design or updates to an existing one, we’re here to help.",
    },
    {
      id: 7,
      question: "Do you offer property valuation and appraisal services?",
      answer: "While we focus on construction and development, we can guide you toward property value appreciation through well-planned layouts and designs.",
    },
    {
      id: 8,
      question: "Can you help improve the value of my property?",
      answer: "Yes, our strategic layout designs, quality construction, and landscaping services can significantly enhance the value and appeal of your property.",
    },
    // {
    //   id: 9,
    //   question: "Do you handle renovations and remodeling projects?",
    //   answer: "Yes, we provide renovation and remodeling services, including interior design and structural upgrades, to give your property a fresh, modern look.      ",
    // },
    // {
    //   id: 10,
    //   question: "Do you provide warranties or guarantees for your work?",
    //   answer: "Yes, we stand by the quality of our work and provide warranties for materials and construction. Specific terms depend on the project type.",
    // },
    // {
    //   id: 11,
    //   question: "Can you handle both residential and commercial projects?",
    //   answer: "Yes, we handle a wide range of projects, including residential buildings, commercial complexes, and mixed-use developments.",
    // },
  ];
  