import React from 'react';
import { Container, Grid, Card, CardContent, CardMedia, Typography, Box } from '@mui/material';
import HeaderText from '../components/HeaderText';
import Founders from '../components/Founders';
import { teamMembers } from '../data/team';
import organogram from "../assets/img/organogram.PNG"
import { departments } from '../data/departments';

const TeamPage = () => {
  return (
    <main 
    style={{
      padding: "0 0 7rem 0",
      minHeight: "100vh",
      position: "relative",
      backgroundImage: "linear-gradient(135deg, #000000 0%, #333333 100%)",
      backgroundAttachment: "fixed",
      backgroundSize: "cover",
    }}
    >
      <section id='founders' style={{ padding: '7rem 0 0 0 ', position:'relative', overflow:'hidden' }}>
        <Container>
          <Founders color={"#ffffff"}/>
        </Container>
      </section>

      <section id='teams' style={{ }}>
        <Container>
            <HeaderText text={"Meet Our Team"} color={"#ffffff"}/>    
          <Grid container spacing={4} >
            {teamMembers.map((member) => (
              <Grid item xs={12} sm={6} md={4} key={member.id}>
                <Card sx={{ textAlign: 'center', borderRadius: '12px', boxShadow: 3 , border:"1px solid #ffffff",
                      margin:{xs:"0rem 1rem", md:0}

                }}
                >
                  <CardMedia
                    component="img"
                    height={"350"}
                    image={member.image}
                    alt={member.name}
                    sx={{
                      objectFit: 'cover', 
                      borderRadius: '12px 12px 0 0',
                    }}
                  />
                  <CardContent>
                    <Typography fontSize={{xs:"1rem", sm:"1rem", md:"1.1rem"}} gutterBottom>
                      {member.name}
                    </Typography>
                    <Typography fontSize={".8rem"} color="text.secondary">
                      {member.role}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </section>

      <section id='departments' style={{ padding: '7rem 0 0 0' }}>
      <Container style={{ textAlign: 'center' }}>
        <HeaderText text={"Our Departments"} color={"#ffffff"}  />
        
        <Grid container spacing={{xs:2, md:4}} justifyContent="center">
          {departments.map((department, index) => (
            <Grid item xs={6} sm={6} md={4} lg={3} key={index}>
              <Box
              height= {160}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: '#2c2c2c',
                  borderRadius: '8px',
                  border:"1px solid #ffffff30",
                  padding: {xs:'1rem', md:'1.5rem'},
                  textAlign: 'center',
                }}
              >
                {department.icon}
                <Typography fontSize= {{xs:'1rem', md:'1.1rem'}} style={{ color: '#ffffff' }}>
                  {department.title}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
      </section>

      <section id='organogram' style={{ padding: '7rem 0 0 0' }}>
        <Container>
        <Box style={{ 
              margin:{xs:"0 1rem", md: 0}
            }}>
          <Card
            sx={{
              backgroundColor:"#ffffff",
              border:"1px solid #ffffff",
              width: '100%',
              padding: {xs:"1rem 1rem",md:"5rem 2rem"},
              borderRadius:'6px',
              marginTop:2
            }}
          >
            <img
              src={organogram}
              alt='Organogram'
              style={{
                maxWidth: '100%',
                height: 'auto',
              }}
            />
          </Card>
          </Box>
        </Container>
      </section>
    </main>
  );
};

export default TeamPage;
