import { Box, Typography } from '@mui/material'
import React from 'react'

const HeaderText = ({text, color}) => {
  return (
    <Box>
        <Typography
            variant="h1"
            fontSize={{ xs: "2rem", sm: "2.5rem", md: "3rem" }}
            fontWeight={700}
            lineHeight={1.2}
            color={color}
            gutterBottom
            sx={{
                letterSpacing: "-0.5px",
                marginBottom: "3rem",
            }}
        >
            {text}
        </Typography>
    </Box>
  )
}

export default HeaderText