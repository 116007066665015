import advantage from "../assets/img/PWAN Affiliates/Advantage.jpg"
import atlantics from "../assets/img/PWAN Affiliates/Atlantics.png"
import diamond from "../assets/img/PWAN Affiliates/Diamond.png"
import edge from "../assets/img/PWAN Affiliates/Edge.jpg"
import elite from "../assets/img/PWAN Affiliates/Elite.png"
import harmony from "../assets/img/PWAN Affiliates/Harmony.jpg"
import haven from "../assets/img/PWAN Affiliates/Haven.jpg"
import heritage from "../assets/img/PWAN Affiliates/Heritage.png"
import homes from "../assets/img/PWAN Affiliates/Homes.jpg"
import legend from "../assets/img/PWAN Affiliates/Legend.png"
import lekki from "../assets/img/PWAN Affiliates/Lekki.png"
import lighthouse from "../assets/img/PWAN Affiliates/Lighthouse.jpg"
import plus from "../assets/img/PWAN Affiliates/Plus.png"
import prime from "../assets/img/PWAN Affiliates/Prime.jpg"
import pro from "../assets/img/PWAN Affiliates/Pro.png"
import royale from "../assets/img/PWAN Affiliates/Royale.jpg"
import signature from "../assets/img/PWAN Affiliates/Signature.png"
import max from "../assets/img/PWAN Affiliates/max.jpg"


export const affiliates = [
    {
        id:1, 
        image: advantage,
        height:40,
        width: 80
    },
    {
        id:2, 
        image: atlantics,
        height:40,
        width: 80
    },
    {
        id:3, 
        image: diamond,
        height:60,
        width: 80
    },
    {
        id:4, 
        image: edge,
        height:30,
        width: 80
    },
    {
        id:5, 
        image: elite,
        height:150,
        width: 250
    },
    {
        id:6, 
        image: harmony,
        height:20,
        width: 80
    },
    {
        id:7, 
        image: haven,
        height:30,
        width: 80
    },
    {
        id:8, 
        image: heritage,
        height:90,
        width: 90
    },
    {
        id:9, 
        image: homes,
        height:30,
        width: 80
    },
    {
        id:10, 
        image: legend,
        height:30,
        width: 80
    },
    {
        id:11, 
        image: lekki,
        height:90,
        width: 90
    },
    {
        id:12, 
        image: lighthouse,
        height:30,
        width: 80
    },
    {
        id:13, 
        image: plus,
        height:70,
        width: 70
    },
    {
        id:14, 
        image: prime,
        height:30,
        width: 80
    },
    {
        id:15, 
        image: pro,
        height:30,
        width: 80
    },
    {
        id:16, 
        image: royale,
        height:30,
        width: 80
    },
    {
        id:17, 
        image: signature,
        height:90,
        width: 90
    },
    {
        id:18, 
        image: max,
        height:90,
        width: 90
    },

]