import * as React from 'react';
import { Box, Container, Grid, Typography, Link } from '@mui/material';
import { FacebookSharp, Instagram, Twitter, Mail, Phone, LocationOnOutlined } from '@mui/icons-material';
import HeaderText from '../components/HeaderText';

const ContactPage = () => {
  return (
    <section 
      style={{ 
        padding: "7rem 0",
        minHeight: "100vh",
        position: "relative",
        backgroundImage: "linear-gradient(135deg, #000000 0%, #333333 100%)",
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
        }}
      >
      <Container maxWidth="lg" sx={{color:"#dee1ec"}} >
        <Grid container spacing={0} b>
          <Grid item xs={12} md={12} sx={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', padding: '2rem 0', margin:"3rem 0" }}>
            <Box sx={{ textAlign: 'center', marginBottom:"3rem" }}>
              <HeaderText text={"Contact Us"} color={"#ffffff"}/>
            </Box>
            <Grid
              container
              display={'flex'}
              alignItems="center"
              item
              xs={12}
              md={12}
              sx={{
                padding: {xs: "0 1rem", md:"0rem 4rem"},
                borderRadius: "10px",
              }}
            >
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "2rem",
                paddingBottom: {xs:"2rem",sm: 0, md: 0},
                borderRight: {xs: 0, sm:"1px solid rgba(255, 255, 255, 0.3)", md:"1px solid rgba(255, 255, 255, 0.3)"},
                borderBottom:{xs: "1px solid rgba(255, 255, 255, 0.3)",sm:0, md: 0},
                textAlign:"left",
              }}
            >
              <Box>
                <Typography fontSize={{xs:"1.2rem", md:"1.5rem"}} gutterBottom color="inherit">
                  Phone
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem", fontSize:"1.2rem" }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Phone sx={{ color: "green", marginRight: "0.5rem", fontSize:{xs:"1.2rem", md:"1.5rem"}}} />
                    <Link href="tel:+2348188800166" underline="none" color="inherit" sx={{fontSize:{xs:"1rem", md:"1.2rem"}}}>
                      +2348188800166
                    </Link>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Phone sx={{ color: "green", marginRight: "0.5rem", fontSize:{xs:"1.2rem", md:"1.5rem"} }} />
                    <Link href="tel:+2349062035074" underline="none" color="inherit"  sx={{fontSize:{xs:"1rem", md:"1.2rem"}}}>
                      +2349062035074
                    </Link>
                  </Box>
                </Box>
              </Box>

              <Box>
                <Typography fontSize={{xs:"1.2rem", md:"1.5rem"}} gutterBottom color="inherit">
                  Email
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", fontSize:"1.2rem" }}>
                  <Mail sx={{ color: "#494949", marginRight: "0.5rem", fontSize:{xs:"1.2rem", md:"1.5rem"} }} />
                  <Link
                    href="mailto:folklandpdc@gmail.com"
                    underline="none"
                    color="inherit"
                    sx={{fontSize:{xs:"1rem", md:"1.1rem"}}}
                  >
                    folklandpdc@gmail.com
                  </Link>
                </Box>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "2rem",
                paddingLeft: {xs: 0,sm:"1rem", md:"2rem"},
                paddingTop:{xs:"2rem", md:0},
                textAlign:"left"
              }}
            >
              <Box>
                <Typography fontSize={{xs:"1.2rem", md:"1.5rem"}} gutterBottom color="inherit">
                  Address
                </Typography>
                <Box sx={{ display: "flex", alignItems: "", fontSize:{xs:"1rem", md:"1.2rem"} }}>
                  <LocationOnOutlined sx={{ color: "#de1b4a", marginRight: "0.5rem", fontSize:{xs:"1.2rem", md:"1.5rem"} }} />
                  <Typography variant="body1" color="inherit" fontSize={{xs:".9rem", md:"1rem"}}>
                  5A & 5B Ikota Villa Estate, Ikota, besides Megachicken,
                  <br />
                  Lekki-Epe Expressway, Ajah, Lagos
                </Typography>
                </Box>
                
              </Box>

              <Box>
                <Typography fontSize={{xs:"1.2rem", md:"1.5rem"}} gutterBottom color="inherit">
                  Social Media
                </Typography>
                <Box sx={{ display: "flex", gap: "1rem" }}>
                  <Link
                    href="https://www.facebook.com/folkland.pdc"
                    target="_blank"
                    rel="noopener noreferrer"
                    color="#22267b"
                  >
                    <FacebookSharp sx={{ fontSize:{xs:"1.2rem", md:"1.5rem"} }} />
                  </Link>
                  <Link
                    href="https://www.instagram.com/folkland_pdc/"
                    target="_blank"
                    rel="noopener noreferrer"
                    color="#ff8364"
                  >
                    <Instagram sx={{ fontSize:{xs:"1.2rem", md:"1.5rem"} }} />
                  </Link>
                  <Link
                    href="https://x.com/search?q=_folklandpdc&src=typed_query&f=top"
                    target="_blank"
                    rel="noopener noreferrer"
                    color="#2e79ba"
                  >
                    <Twitter sx={{ fontSize:{xs:"1.2rem", md:"1.5rem"} }} />
                  </Link>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default ContactPage;