import img1 from "../assets/img/projects/img1.jpg";
import img2 from "../assets/img/projects/img2.jpg";
import img3 from "../assets/img/projects/img3.jpg";
import img4 from "../assets/img/projects/img4.jpg";
import img5 from "../assets/img/projects/img5.jpg";
import img6 from "../assets/img/projects/img6.jpg";
import img7 from "../assets/img/projects/img7.jpg";
import img8 from "../assets/img/projects/img8.jpg";
import img9 from "../assets/img/projects/img9.jpg";
import img10 from "../assets/img/projects/img10.jpg";
import img11 from "../assets/img/projects/img11.jpg";
import img12 from "../assets/img/projects/img12.jpg";
import img13 from "../assets/img/projects/img13.jpg";
import img14 from "../assets/img/projects/img14.jpg";
import img15 from "../assets/img/projects/img15.jpg";
import img16 from "../assets/img/projects/img16.jpg";
import img17 from "../assets/img/projects/img17.jpg";
import img18 from "../assets/img/projects/img18.jpg";
import img19 from "../assets/img/projects/img19.jpg";
import img20 from "../assets/img/projects/img20.jpg";
import img21 from "../assets/img/projects/img21.jpg";
import img22 from "../assets/img/projects/img22.jpg";
import img23 from "../assets/img/projects/img23.jpg";
import img24 from "../assets/img/projects/img24.jpg";
import img25 from "../assets/img/projects/img25.jpg";
import img26 from "../assets/img/projects/img26.jpg";
import img27 from "../assets/img/projects/img27.jpg";




export const galleryData = [
  { id: 1, title: '5 Bedroom Building At Lakowe', image: img1, description: '5 Bedroom Building At Lakowe' },
  { id: 2, title: '5 bedroom Fully Detached Building', image: img2, description: '5 bedroom Fully Detached Building, Royal Haven At Monastery Rd, Owned by PWAN Haven' },
  { id: 3, title: 'Block Setting For 3 Bedroom Building', image: img3, description: 'Block Setting For 3 Bedroom Building at Metro city' },
  { id: 4, title: 'Block Setting Out For 3 Bedroom Bungalow', image: img4, description: 'Block Setting Out For 3 Bedroom Bungalow At Metro city, Oshoroko, Lagos' },
  { id: 5, title: 'Cedarwood Luxury at Ajayi Apata', image: img5, description: 'Cedarwood Luxury at Ajayi Apata, Sangotedo-Abijo. Ibeju Lekki Lagos Nigeria' },
  { id: 6, title: 'Cedarwood Luxury Block Of Flats', image: img6, description: 'Cedarwood Luxury Block Of Flats Ajayi Apata, Lagos' },
  { id: 7, title: 'Cedarwood Luxury Block Of Flats', image: img7, description: 'Cedarwood Luxury Block Of Flats Ajayi Apata, Lagos' },
  { id: 8, title: 'Cedarwood Luxury Bungalow Projects', image: img8, description: 'Cedarwood Luxury Bungalow Projects at Oshoroko' },
  { id: 9, title: 'Cedarwood Luxury Road Infrastructure', image: img9, description: 'Cedarwood Luxury Road Infrastructure' },
  { id: 10, title: 'Cedarwood Luxury Setting Out for Block of Flat', image: img10, description: 'Cedarwood Luxury Setting Out for Block of Flat, Ajayi Apata, Lagos' },
  { id: 11, title: 'Cedarwood Luxury Terrace Building', image: img11, description: 'Cedarwood Luxury Terrace Building, Ajayi Apata, Lagos' },
  { id: 12, title: 'Construction of 5 Bedroom Duplex at Royal Haven', image: img12, description: 'Construction of 5 Bedroom Duplex at Royal Haven' },
  { id: 13, title: 'Construction of 5 Bedroom Building at Metro City', image: img13, description: 'Construction of 5 Bedroom Building at Metro City' },
  { id: 14, title: 'Construction of Cedarwood Luxury at Metro City', image: img14, description: 'Construction of Cedarwood Luxury at Metro City' },
  { id: 15, title: 'Construction of Road Infrastructure and Pavements', image: img15, description: 'Construction of Road Infrastructure and Pavements at Cedarwood luxury, Ajayi apata' },
  { id: 16, title: 'Foundation Casting for 3 Bedroom Bungalow at Cedarwood Luxury', image: img16, description: 'Foundation Casting for 3 Bedroom Bungalow at Cedarwood Luxury, Oshoroko, Lagos' },
  { id: 17, title: 'Foundation Casting for 3 Bedroom Bungalow at Cedarwood Luxury', image: img17, description: 'Foundation Casting for 3 Bedroom Bungalow at Cedarwood Luxury, Oshoroko, Lagos' },
  { id: 18, title: 'Foundation Casting for 3 Bedroom Bungalow at Cedarwood Luxury', image: img18, description: 'Foundation Casting for 3 Bedroom Bungalow at Cedarwood Luxury, Oshoroko, Lagos' },
  { id: 19, title: 'Foundation Levelling at Cedarwood Luxury', image: img19, description: 'Foundation Levelling at Cedarwood Luxury, Oshoroko, Lagos' },
  { id: 20, title: 'Foundation Levelling at Cedarwood Luxury', image: img20, description: 'Foundation Levelling at Cedarwood Luxury, Oshoroko, Lagos' },
  { id: 21, title: 'Scene Construction Site With Equipment', image: img21, description: 'Scene Construction Site With Equipment' },
  { id: 22, title: 'Setting Out at Cedearwood Luxury', image: img22, description: 'Setting Out at Cedearwood Luxury' },
  { id: 23, title: 'Setting Out for 3 Bedroom Bungalow at Cedarwood Luxury', image: img23, description: 'Setting Out for 3 Bedroom Bungalow at Cedarwood Luxury, Oshoroko, Lagos' },
  { id: 24, title: 'Setting Out for 5 Bedroom Duplex Project', image: img24, description: 'Setting Out for 5 Bedroom Duplex Project in Asaba' },
  { id: 25, title: 'Setting Out of 3 Bedroom Bungalow', image: img25, description: 'Setting Out of 3 Bedroom Bungalow at Metro City' },
  { id: 26, title: 'Site Clearing for New Estate Development', image: img26, description: 'Site Clearing for New Estate Development at Royal Haven Abijo' },
  { id: 27, title: 'Zion Residence, Orchid Rd, Eleganza Bus Stop Ajah, Lagos', image: img27, description: 'Zion Residence, Orchid Rd, Eleganza Bus Stop Ajah, Lagos - Owned by PWAN Pro 4 Bedroom Semi Detached Building' },
];