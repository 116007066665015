export const values = [
      {
        letter: "A",
        color: "#eb2632",
        description: "Accountability"
      },
      {
        letter: "R",
        color: "#240747",
        description: "Responsibility",
      },
      {
        letter: "I",
        color: "#f0ab8d",
        description: "Integrity",
      },
      {
        letter: "S",
        color: "#ffc93c",
        description: "Service",
      },
      {
        letter: "E",
        color: "#57385c",
        description: "Excellence",
      },
];