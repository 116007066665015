import { LocalShipping, Build, ShoppingCart, Science, AccountBalance, WorkOutline} from '@mui/icons-material'

export const departments = [
    {
      title: "Administrative",
      icon: <WorkOutline sx={{ fontSize: {xs:30, md:40}, color: '#dc2f2f', marginBottom: '1rem' }} />,
    },
    {
      title: "Accounts",
      icon: <AccountBalance sx={{ fontSize: {xs:30, md:40}, color: '#dc2f2f', marginBottom: '1rem' }} />,
    },
    {
      title: "Research and Design",
      icon: <Science sx={{ fontSize: {xs:30, md:40}, color: '#dc2f2f', marginBottom: '1rem' }} />,
    },
    {
      title: "Costing and Procurement",
      icon: <ShoppingCart sx={{ fontSize: {xs:30, md:40}, color: '#dc2f2f', marginBottom: '1rem' }} />,
    },
    {
      title: "Project, Planning and Construction",
      icon: <Build sx={{ fontSize: {xs:30, md:40}, color: '#dc2f2f', marginBottom: '1rem' }} />,
    },
    {
        title: "General Logistics",
        icon: <LocalShipping sx={{ fontSize: {xs:30, md:40}, color: '#dc2f2f', marginBottom: '1rem' }} />,
      },
  ];
