
import { FacebookSharp, Instagram, Twitter, Mail, Phone } from "@mui/icons-material";
import { Box, Container, Grid, Link, Typography } from "@mui/material";

const Footer = () => {

  const mapURL = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.3712826202855!2d3.6087375736494827!3d6.474567823732812!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103bf93d54806741%3A0x27acac2e43764031!2sFOLKLAND%20PDC!5e0!3m2!1sen!2sng!4v1735554664189!5m2!1sen!2sng"

  return (
    <footer style={{ backgroundColor: "#f9f9f9", borderTop: "1px solid #eee", position: "relative" }}>
      <Box
        sx={{
          position: "relative",
          width:{xs:"100%", sm:"100%", md:"80%"},
          height: {xs:"200px", md:"300px"}, 
          overflow: "hidden", 
          margin:"auto",
          zIndex: 1,
        }}
      >
        <iframe
          src={mapURL}
          title="Folklandpdc Head Office"
          style={{
            width: "100%",
            height: "100%",
            border: 0,
          }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </Box>

      <Container maxWidth="lg" sx={{ marginTop: "-20px" }}>
        <Box sx={{ padding: "5rem 0" }}>
          <Grid container spacing={4} alignItems="flex-start">
            <Grid item xs={12} sm={6} md={3}>
              <Box sx={{ maxWidth: { xs: "80px", md: "100px" }}}>
                <a href="/">
                  <img
                    src={require("../../assets/img/Asset 3.png")}
                    alt="Folkland Logo"
                    style={{ width: "100%", height: "auto" }}
                  />
                </a>
              </Box>
              <Box 
                sx={{ 
                  display: "flex", 
                  flexDirection: "row", 
                  justifyContent: "space-between", 
                  alignItems: "center", 
                  marginTop: {xs:"1rem", md:"1rem"}, 
                  marginRight:{xs: 41, md: 20},
                  marginLeft: {xs: 0, md:1}
                }}
              >
                <Link href="https://www.instagram.com/folkland_pdc/" target="_blank" rel="noopener noreferrer">
                  <Instagram sx={{ color: "#ff8364", fontSize: { xs: "23px", sm: "23px", md: "23px" } }} />
                </Link>
                <Link href="https://www.facebook.com/folkland.pdc" target="_blank" rel="noopener noreferrer">
                  <FacebookSharp sx={{ backgroundColor:"", color: "", fontSize: { xs: "23px", sm: "23px", md: "23px" } }} />
                </Link>
                <Link href="https://x.com/search?q=_folklandpdc&src=typed_query&f=top" target="_blank" rel="noopener noreferrer">
                  <Twitter sx={{ color: "#2e79ba", fontSize: { xs: "23px", sm: "23px", md: "23px" } }} />
                </Link>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
            <Typography
                   fontSize={{ xs: "1rem", sm: "1.3rem", md: "1.2rem" }}
                   sx={{ fontWeight: 600, marginBottom: "1rem" }}
                 >
                   Contact Us
                 </Typography>
              <Box sx={{ display: "flex", alignItems: "center", marginBottom: "0.75rem" }}>
                   <Phone sx={{ color: "#0e2431", fontSize: { xs: "14px", sm: "16px", md: "16px" }, marginRight: "0.5rem" }} />
                   <a href="tel:+2348188800166" style={{ textDecoration: "none", color: "#000" }}>
                     +2348188800166
                   </a>
                 </Box>
                 <Box sx={{ display: "flex", alignItems: "center", marginBottom: "0.75rem" }}>
                   <Phone sx={{ color: "#0e2431", fontSize: { xs: "14px", sm: "16px", md: "16px" }, marginRight: "0.5rem" }} />
                   <a href="tel:+2349062035074" style={{ textDecoration: "none", color: "#000" }}>
                     +2349062035074
                   </a>
                 </Box>
                 <Box sx={{ display: "flex", alignItems: "center" }}>
                   <Mail sx={{ color: "#0e2431", fontSize: { xs: "14px", sm: "16px", md: "16px" }, marginRight: "0.5rem" }} />
                   <a href="mailto:folklandpdc@gmail.com" style={{ textDecoration: "none", color: "#000" }}>
                     folklandpdc@gmail.com
                   </a>
                 </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
            <Typography
                  fontSize={{ xs: "1rem", sm: "1.3rem", md: "1.2rem" }}
                  sx={{ fontWeight: 600, marginBottom: "1rem" }}
                >
                  Head Office
                </Typography>
              <Typography fontSize={{ xs: "14px", sm: "16px", md: "16px" }} sx={{ marginBottom: "1rem" }}>
                  5A & 5B Ikota Villa Estate, Ikota, besides Megachicken, Lekki-Epe Expressway, Ajah, Lagos
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ textAlign: "center", padding: "2rem 0", borderTop: "1px solid #eee" }}>
          <Typography
              fontSize={{xs:"12px", sm:"12px", md:"14px"} }
              fontWeight={400}
              color="#000000"
              gutterBottom
              sx={{
              textAlign: "center",
              letterSpacing: "-0.5px",
              }}
          >
              &copy; Folklandpdc {new Date().getFullYear()} | All rights reserved.
          </Typography>
        </Box>
        
      </Container>
    </footer>
  );
};

export default Footer;
