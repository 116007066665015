import ceo from "../assets/img/Dr Frederick Okpaje MD PWAN  HAVEN.jpg"
import md from "../assets/img/Oyedemi Julius MANAGING DIRECTOR.jpg"
import exe from "../assets/img/Executive Director Folkland.jpg"
import ope from "../assets/img/Operation Manager Folkland.jpg"
import acc from "../assets/img/departments/HOD - Account.jpg"
import cost from "../assets/img/departments/HOD - Costing and Procurement.jpg"
import pro from "../assets/img/departments/HOD - Project, Planning, and Construction.jpg"
import research from "../assets/img/departments/HOD - Research and Design Department.jpg"
import admin from "../assets/img/departments/HOD Administrative.jpg"


export const teamMembers = [
    { id: 1, name: 'Dr. Frederick Okpaje', role: 'Chief Executive Officer', image: ceo },
    { id: 2, name: 'Prof. (Amb.) Julius Oyedemi', role: 'Managing Director', image: md },
    { id: 3, name: 'Engr. Olasupo Dada', role: 'Executive Director', image: exe },
    { id: 4, name: 'Q.S Akindele Agboola John', role: 'Operational Manager', image: ope },
    { id: 5, name: 'Oladeji Olorunda MBA, ACICPFM, CIPM', role: 'HOD Accounting', image: acc },
    { id: 6, name: 'Mrs Anthonia Uju', role: 'HOD Costing and Procurement', image: cost },
    { id: 7, name: 'Engr. Amos Achegbulu', role: 'HOD Project, Planning and Construction', image: pro },
    { id: 8, name: 'Tpl. Victor Ajayi', role: 'HOD Research and Design Department', image: research },
    { id: 9, name: 'Mrs Rosekate Nwachukwu', role: 'HOD Administrative', image: admin },
  ];
  
export const departments = [
    
];