import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  Card,
  CardMedia,
  Button,
} from "@mui/material";
import { ArrowDownward } from "@mui/icons-material";
import { galleryData } from "../data/gallery";
import HeaderText from "../components/HeaderText";

const GalleryPage = () => {
  const [visibleItems, setVisibleItems] = useState(12);
  const [loaded, setLoaded] = useState(false);

  const loadMore = () => {
    setVisibleItems((prev) => prev + 4);
  };

  useEffect(() => {
    const timeout = setTimeout(() => setLoaded(true), 100);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <section
      style={{
        padding: "7rem 0",
        minHeight: "100vh",
        position: "relative",
        backgroundImage: "linear-gradient(135deg, #000000 0%, #333333 100%)",
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
      }}
    >
      <Container>
        <Box sx={{ textAlign: "center", padding: "3rem 0" }}>
          <HeaderText text={"Our gallery"} color={"#fff"} />
          <Typography
            variant="h6"
            sx={{ marginBottom: { xs: "1rem", md: "3rem" }, color: "#aaa" }}
          >
            A glimpse of our work and projects.
          </Typography>
        </Box>

        <Grid container spacing={4}>
          {galleryData.slice(0, visibleItems).map((item, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              key={item.id}
              sx={{
                opacity: loaded ? 1 : 0,
                transform: loaded ? "translateY(0)" : "translateY(-100px)",
                transition: `opacity 0.6s ease ${
                  index * 0.2
                }s, transform 0.6s ease ${index * 0.2}s`,
              }}
            >
              <Card
                sx={{
                  position: "relative",
                  margin: { md: "0", xs: "0 1rem" },
                  height: "290px",
                  overflow: "hidden",
                  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.5s ease, box-shadow 0.5s ease",
                  zIndex: 1,
                  "&:hover": {
                    transform: "scale(1.1)",
                    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                    zIndex: 2,
                    "& .descriptionBox": {
                      bottom: 0,
                    },
                  },
                }}
              >
                <CardMedia
                  component="img"
                  height="290"
                  image={item.image}
                  alt={item.title}
                  sx={{
                    objectFit: "cover",
                    transition: "transform 0.3s ease",
                  }}
                />
                <Box
                  className="descriptionBox"
                  sx={{
                    position: "absolute",
                    bottom: "-80px",
                    left: 0,
                    width: "100%",
                    height: "80px",
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    transition: "bottom 0.5s ease",
                    zIndex: 10,
                  }}
                >
                  <Typography
                    variant="body2"
                    style={{ margin: "0 10px", textAlign: "center" }}
                  >
                    {item.description}
                  </Typography>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>

        {visibleItems < galleryData.length && (
          <Box
            sx={{
              textAlign: "center",
              marginTop: "2rem",
              fontSize: { xs: "1rem", md: "1.2rem" },
            }}
          >
            <Button
              variant="contained"
              onClick={loadMore}
              sx={{
                color: "#dc2f2f",
                backgroundColor: "transparent",
                boxShadow: "0",
                padding: "0.8rem 2rem",
                borderRadius: "8px",
                "&:hover": {
                  boxShadow: 0,
                  backgroundColor: "#ffe0e0",
                  borderRadius: 0,
                },
              }}
            >
              Load More
              <ArrowDownward sx={{ marginLeft: 1 }} />
            </Button>
          </Box>
        )}
      </Container>
    </section>
  );
};

export default GalleryPage;
