import {Landscape, DesignServices, BuildOutlined, Map, Engineering} from '@mui/icons-material'

export const services = [
    {
      title: "Architectural design & Consultancy",
      icon: <DesignServices sx={{ fontSize: {xs:30, md:40} }} />,
    },
    {
      title: "Structural engineering designs & Consultancy",
      icon: <Engineering sx={{ fontSize: {xs:30, md:40} }} />,
    },
    {
      title: "Surveying, urban planning",
      icon: <Map sx={{ fontSize: {xs:30, md:40} }} />,
    },
    {
      title: "Civil works & building construction",
      icon: <BuildOutlined sx={{ fontSize: {xs:30, md:40} }} />,
    },
    {
      title: "Interior design & Landscaping",
      icon: <Landscape sx={{ fontSize: {xs:30, md:40} }} />,
    },
  ];
